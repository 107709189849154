.headerItem {
  font-weight: 600;
  font-size: 12px;
  padding: 12px;
  background-color: #f1f3fa;
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  color: #2d2d2d;
}

.clickable {
  cursor: pointer;
  transition: 0.2s ease;

  &:hover {
    background-color: #eceffa;
  }
}

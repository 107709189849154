.wrapper {
  width: calc(100% - 24px);
  overflow: auto;

  border: 1px solid #e5e5e5;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  background-color: #e4eef8;
  margin: 12px;
}

.content {
  width: 100%;
  display: grid;
  min-width: 600px;
  grid-template-columns: minmax(140px, 200px) minmax(160px, 200px) repeat(4, fit-content(200px));
  grid-gap: 1px;
  border-radius: 12px;

  @media screen and (min-width: 880px) {
    grid-template-columns: minmax(180px, 200px) minmax(160px, 200px) repeat(4, minmax(140px, 1fr));
    min-width: 800px;
  }

  @media screen and (min-width: 1200px) {
    grid-template-columns: minmax(190px, 260px) minmax(170px, 200px) repeat(4, minmax(140px, 1fr));
  }
}

.headerItem {
  font-weight: 600;
  font-size: 12px;
  padding: 12px;
  background-color: #e5e5e5;
}

.coinDetails {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.name {
  display: flex;
  flex-direction: column;
}

.coinName {
  font-weight: 700;
  font-size: 12px;

  @media screen and (min-width: 992px) {
    font-size: 15px;
  }
}

.logo {
  width: 26px;
  height: 26px;

  @media screen and (min-width: 992px) {
    width: 30px;
    height: 30px;
  }
}

.symbol {
  font-size: 12px;
  color: #555;
}

.rank {
  font-size: 12px;
  color: #555;
}

.tableCell {
  font-size: 12px;
  background-color: #fff;
  padding: 8px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  color: #2d2d2d;

  @media screen and (min-width: 992px) {
    font-size: 14px;
  }
}

.profit {
  font-weight: 600;
}

.date {
  font-size: 11px;
  color: #444;

  svg {
    flex-shrink: 0;
  }

  @media screen and (min-width: 992px) {
    font-size: 13px;
  }
}

.tableCellBackground {
  @extend .tableCell;
  background-color: #f7f9fa;
}

.percentageTableCell {
  @extend .tableCell;
  color: #000;
  font-weight: 500;
}

.percentageTableCellBackground {
  @extend .percentageTableCell;
  background-color: #f7f9fa;
}

.tableCellMinus {
  color: #e0482d;
}

.tableCellPlus {
  color: #00984c;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0;
  font-size: 10px;
  padding: 0;

  svg {
  }

  @media screen and (min-width: 992px) {
    font-size: 12px;
  }
}

.profitBadge {
  gap: 0;
  border: 1px solid #afd7af;

  svg {
    color: #228b22;
  }

  span {
    color: #228b22;
  }
}

.lossBadge {
  gap: 0;
  border: 1px solid #e1c8c3;

  svg {
    color: #e0482d;
  }

  span {
    color: #e0482d;
  }
}

.badge {
  font-size: 12px;
  line-height: 16px;
  background-color: #f4f4f5ff;
  color: #2d2d2d;
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  border-radius: 8px;
}

.loaderWrapper {
  width: 100%;
  height: 200px;
  display: grid;
  place-items: center;
  grid-column: 1/9;
  background-color: #fff;
}

.loader {
  width: 10px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}

@keyframes l5 {
  0% {
    box-shadow:
      20px 0 #ccc,
      -20px 0 #ccc2;
    background: #ccc;
  }
  33% {
    box-shadow:
      20px 0 #ccc,
      -20px 0 #ccc2;
    background: #ccc2;
  }
  66% {
    box-shadow:
      20px 0 #ccc2,
      -20px 0 #ccc;
    background: #ccc2;
  }
  100% {
    box-shadow:
      20px 0 #ccc2,
      -20px 0 #ccc;
    background: #ccc;
  }
}
